<template>
    <div :style="props.customZIndex ? `z-index: ${props.customZIndex}` : ''" class="overlay" :class="[{ 'items-center': !props.verticalAlign, 'overlay--visible': props.isVisible, 'overlay--invisible': !props.isVisible }, props.verticalAlign, props.customClass]" @click.self="emit('click')">
        <slot></slot>
    </div>
</template>
<script setup>

const props = defineProps({
    isVisible: Boolean,
    customZIndex: Number,
    customClass: String,
    verticalAlign: String
});

const emit = defineEmits(['click']);

</script>